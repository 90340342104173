<template>
  <v-combobox
    v-if="type === 'combobox'"
    v-bind="{ ...$attrs, ...$props }"
    autocomplete="false"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        small
        :color="colorChips ? data.item.value : 'primary'"
        :text-color="'white'"
        close
        close-icon="mdi-close-circle"
        @click:close="handleCloseChip(data)"
      >
        <template v-if="data.item.name">
          {{ data.item.name }}
        </template>

        <template v-else>
          {{ data.item }}
        </template>
      </v-chip>
    </template>
  </v-combobox>
  <v-select
    v-else
    v-bind="{ ...$attrs, ...$props }"
    autocomplete="false"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="data">
      <v-chip
        small
        color="primary"
        close
        close-icon="mdi-close-circle"
        @click:close="handleCloseChip(data)"
      >
        <template v-if="data.item.name">
          {{ data.item.name }}
        </template>

        <template v-else>
          {{ data.item }}
        </template>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'SelectChips',
  props: {
    type: {
      type: String,
      default: 'combobox'
    },
    colorChips: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const handleCloseChip = data => {
      emit('drop-item', data.item?.id || data.item);
    };

    return {
      handleCloseChip
    };
  }
};
</script>
